import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/DiscoverLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`CKEditor 5 is a powerful open source rich text editing framework. It is integrated in CoreMedia Studio and extended by
CoreMedia plugins for editing of CoreMedia rich text content. You can adapt CKEditor 5 to your needs and write own plugins.`}</p>
    <h2>{`Use Cases`}</h2>
    <ul>
      <li parentName="ul">{`Integrating standard plugins from CKEditor marketplace`}</li>
      <li parentName="ul">{`Adapting configurations`}</li>
      <li parentName="ul">{`Creating custom plugins`}</li>
    </ul>
    <h2>{`Resources`}</h2>
    <ul>
      <li parentName="ul">{`Learn how to configure and create plugins for the CKEditor in the `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/cmcc-12/current/webhelp/studio-developer-en/content/Section_CKEditor5_Introduction.html"
        }}>{`Studio Developer Manual`}</a>{`.`}</li>
      <li parentName="ul">{`As an example, study the `}<a parentName="li" {...{
          "href": "https://github.com/CoreMedia/ckeditor-plugins/"
        }}>{`CoreMedia CKEditor 5 Plugins`}</a>{` workspace. Here,
you will find plugins which CoreMedia has created for the integration of CKEditor 5 into Studio.`}</li>
      <li parentName="ul">{`See the official `}<a parentName="li" {...{
          "href": "https://ckeditor.com/docs/ckeditor5/35.2.0/"
        }}>{`CKEditor 5 documentation`}</a>{` for more details.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      