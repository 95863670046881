import React from 'react'
import styled from 'styled-components'
import { Col, Row } from 'react-grid-system'
import Seo from '../components/SEO'
import Toc from '../components/Toc'
import usePrism from '../hooks/use-prism'
import ArticleHeader from '../components/ArticleHeader'
import ContainerWrapper from '../components/ContainerWrapper'
import Hidden from '../components/Responsive/Hidden'
import Visible from '../components/Responsive/Visible'

const Style = styled.div`
  .article {
    position: relative;
  };
    .sidebar {
    background-color: ${props => props.theme.palette.gray[ '500' ]};
  }
`

const DiscoverTemplate = ({ children, toc, title, frontmatter, modifiedGmt, createdGmt, performTime, crumbs }) => {
  const container = React.useRef()

  usePrism(container)

  return (
    <ContainerWrapper crumbs={crumbs} withFeedback>
      {/*  <h1>{frontmatter.title}</h1> */}
      <Row nowrap={'nowrap'} nogutter>
        {/*    <Col className={'sidebar'} id={'sidebar'} xs={'content'} style={{ paddingLeft: 0, paddingRight: 0, minWidth: 0}}>
          <div>Test Test Test</div>
          <Hidden xs sm style={{ width: '18rem' }}>
            <Sidebar/>
          </Hidden>
        </Col> */}
        <Col style={{ minWidth: 0 }}>
          <Style>
            <Seo
              article={true}
              title={title}
              description={frontmatter.abstract}
              modifiedGmt={modifiedGmt}
              performTime={performTime}
            />
            <div ref={container} className={'article'}>

              <ArticleHeader
                frontmatter={frontmatter}
                toc={<Visible xs sm md><Toc toc={toc} inline={true} frontmatter={frontmatter}/></Visible>}
                modifiedGmt={modifiedGmt}
                createdGmt={createdGmt}
              />
              {children}
            </div>
          </Style>
        </Col>
        <Col xs={'content'} id={'toc2'} style={{ minWidth: 0 }}>
          <Hidden xs sm md style={{ width: '18rem' }}>
            {toc && <Toc toc={toc} frontmatter={frontmatter}/>}
          </Hidden>
        </Col>
      </Row>
    </ContainerWrapper>
  )
}

export default DiscoverTemplate
